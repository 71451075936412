const base_url = 'http://188.245.58.75';

export const environment = {
   production: false,
   AUTH_API_URL: base_url + ':8081/api/',
   CHAT_WEBSOCKET_URL: 'ws://188.245.58.75:8082/ws',
   CHAT_URL: 'http://188.245.58.75:8082/api/v1/',
   NOTIF_WEBSOCKET_URL: 'ws://188.245.58.75:8084/ws',
   NOTIF_API_URL: base_url + ':8084/noti/',
   //API_URL: base_url + '/quiz/',
   API_URL: 'http://188.245.58.75:5050/quiz/',
   RESUME_URL: 'http://188.245.58.75:5020/resume/',
   WALLET_API_URL: base_url + ':8083/api/',
   // POST_API_URL: base_url + '/social/',
   // RESUME_URL: base_url + '/resume/',
   POST_API_URL: 'http://188.245.58.75:5090/social/',
   GOOGLE_API_KEY: 'AIzaSyDaYmOM3TBU0HOp6CDSijGnna2hpHw1Zf8',
   IMAGE_COMPRESSION_BASE_URL: 'tiktera.com:9500',
   IMAGE_COMPRESSION_BASE_URL_NEW: 'tiktera.com/media',
   DOMAIN: base_url,

   whiteListUrls: [
      base_url + ':8081/api/auths',
      base_url + ':8081/api/login',
      base_url + ':8081/api/token',
      'maps',
      base_url + ':8081/api/auths/logout',
      ':5020/resume/references/withuuid'
   ]
};
